/* components/SearchOverlay.module.css */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  color: white;
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  padding: 1rem;
  padding-top: 7rem;
  gap: 1rem;
}

.backdrop.show {
  opacity: 1;
  pointer-events: all;
  overflow-y: auto;
}

.overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: scale(0.9);
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  gap: 1rem;
}

.overlay.show {
  transform: scale(1);
  opacity: 1;
}
.searchResults {
  text-align: center;
  padding: 1rem;
  padding-bottom: 0.4rem;
  border-bottom: 1px solid #f26b0b;
  margin-bottom: 1rem;
}
